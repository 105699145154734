
<div class="payment-container">
    <span class="material-icons failure">
        clear
    </span>
<p class="fw-600">
    Payment Failed
</p>
<p>
    Please check your card and try again. If the problem continues, speak to your bank for more details.
</p>
</div>