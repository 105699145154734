<div class="container">
  <div class="img-col" *ngIf="intent && !resp && isShowCard">
    <img [src]="parentclubImage" class="parent">
    <h3 class="club-name">{{parentClubName}}</h3>
  </div>
  <div id="card-element" *ngIf="isShowCard"  class="form-control" ></div>
  <div *ngIf="intent && !resp && isShowCard" class="input-sec">
    <input [(ngModel)]="name" type="text" class="form-control name" placeholder="Cardholder Name"  >
    <button type="submit" (click)="chargeCard()" class="btn btn-primary ">
      Pay
    </button>
  </div>
  
  <!-- <div class="d-flex justify-content-center" id="spinner">
    <div class="spinner-border text-primary" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div> -->
  <div id="loader">
    <div></div>
  </div>
  <div [innerHTML]="page" *ngIf="intent && resp" class="container"></div>


</div>