import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { StripeCardElementOptions, StripeElementsOptions } from '@stripe/stripe-js';
import { StripeCardComponent, StripeService } from 'ngx-stripe';
import {loadStripe} from '@stripe/stripe-js';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-stripe',
  templateUrl: './stripe.component.html',
  styleUrls: ['./stripe.component.css']
})

export class StripeComponent implements OnInit {
  @ViewChild(StripeCardComponent) card: StripeCardComponent;
  session;
  intent:boolean = false;
  resp:boolean = false;
  isShowCard:boolean = true
  page:any = "";
  intentMeta:any = {};
  parentClubName:any;
  stripe;
  element;
  cardObj;
  parentclubImage;
  name:any = "";
  env:string = 'dev';
  url = "https://activitypro-nest-261607.appspot.com";
  cardVerified:boolean =  false;
  cardOptions: StripeCardElementOptions = {
    style: {  
      base: {
        iconColor: '#666EE8',
        letterSpacing:'2',
        color: '#31325F',
        fontWeight: '300',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSize: '15px',
        '::placeholder': {
          color: '#CFD7E0'
        }
      }
    }
  };
  constructor( private router: Router,private toastr: ToastrService,private stripeService:StripeService,private route:ActivatedRoute,private fb: FormBuilder,private http:HttpClient) {
    this.session = route.snapshot.paramMap.get("session")
    if(this.env.toLocaleLowerCase() == 'dev'){
      this.url = "https://oonxvy0hcd.execute-api.eu-west-2.amazonaws.com/DEV";
    }
    this.checkTokenType(this.session);
    this.parentclubImage = "https://lh3.googleusercontent.com/akI0AgepdYROuNLUWfQ5ytBc--JivRx6YzVRZanITTrcN5mieLdSuaWILY_mLa50Jg";
    // this.stripeService.options;
    // this.stripeService.confirmCardPayment(this.session).subscribe((data)=>{
    //   console.log(data)
    // })

   // this.stripeService.
   // this.fetch(session);
   }

   async checkTokenType(token){
    try {
      this.showloader();
      this.intentMeta = await this.getTokenDetails(token);
      this.parentclubImage = this.intentMeta["ParentClubAppIconURL"]
      this.parentClubName = this.intentMeta["ParentClubName"]
      this.hideloader();
      this.stripe = await loadStripe('pk_test_sKwY3Ldq0Nip6piTzEQt2glg',{
        stripeAccount:this.intentMeta.ConnectedAccountID
      });
      this.intent = true;
      this.element = this.stripe.elements();
      this.cardObj = this.element.create('card',this.cardOptions)
      this.cardObj.mount('#card-element');
      this.cardObj.on('change',(ev)=>{
        if(ev.complete){
          this.cardVerified = true;
        }
      })
    } catch (error) {
        this.redirectToCheckout(token)
    }
   }
   getTokenDetails(token){
    return new Promise((res,rej)=>{
      this.http.get(`${this.url}/stripeconnect/intentmetadata/${token}`).subscribe((details:any)=>{
        if(details.data){
          res(details.data)
        }else(
          rej(null)
        )
      },err=>{
        rej(null)
      })
    })
   }

   redirectToCheckout(token){
    this.hideloader();
    this.stripeService.redirectToCheckout({
      sessionId:token
    }).subscribe((data)=>{

    })
   }



  elementsOptions: StripeElementsOptions = {
    locale: 'auto'
  };

  stripeTest: FormGroup;

  ngOnInit(): void {
    this.stripeTest = this.fb.group({
      name: ['', [Validators.required]]
    });
  }

  chargeCard(): void {
    if(!this.cardVerified){ 
      this.toastr.error('Fill card details')
    }else if(!this.name ){
      this.toastr.error('Enter name')
    }else{
      this.showloader();
      this.intentMeta.ConnectedAccountID
      //const card= this.card.element;
     // const name = this.stripeTest.get('name').value;
     //this.stripeService.confirmCardPayment()
    // this.stripeService.
      this.stripe.confirmCardPayment(this.session,{
        payment_method:{   
          card:this.cardObj,
          billing_details: {
            name: this.name,
          },
        }
      })
      .then(
        (data)=>{
        
          console.log(data)
          this.isShowCard = false;
          if(data.error){
  
            this.failsPayment()
          }else if(data.paymentIntent){
            if(data.paymentIntent.status == 'succeeded'){
              this.successPayment()
              
            }else{
              this.failsPayment()
            }
          }
        }
       )  
    }
  }

  async successPayment(){
    this.hideloader();
    this.http.get(`${this.url}/stripeconnect/updatepaymentstatus/${this.intentMeta.PaymentType}/${this.intentMeta.PaymentGateWayKey}`).subscribe((data: any) => {
     // this.page = data.data;
      this.resp = true;
    })
    this.router.navigate(['/payment-success']);
  }
  async failsPayment(){
    this.hideloader();
    this.http.get(`${this.url}/stripeconnect/updatepaymentstatus/${this.intentMeta.PaymentType}/${this.intentMeta.PaymentGateWayKey}`).subscribe((data: any) => {
     // this.page = data.data;
      this.resp = true;
    })
    this.router.navigate(['/payment-failure']);
    //this.
  }

  async showloader() { 
    // Setting display of spinner 
    // element to none 
    document.getElementById('loader').style.display = 'block'; 
  }
  async hideloader() { 
    // Setting display of spinner 
    // element to none 
    document.getElementById('loader').style.display = 'none'; 
  } 
}
