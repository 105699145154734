import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgxStripeModule } from 'ngx-stripe';
import { StripeComponent } from './stripe/stripe.component';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SubscriptionComponent } from './subscription/subscription.component';
import { PaymentSuccessComponent } from './payment-success/payment-success.component';
import { PaymentFailureComponent } from './payment-failure/payment-failure.component';
@NgModule({
  declarations: [
    AppComponent,
    StripeComponent,
    SubscriptionComponent,
    PaymentSuccessComponent,
    PaymentFailureComponent
  ],
  imports: [
    HttpClientModule,
    ReactiveFormsModule,
    BrowserModule, 
    AppRoutingModule,
    FormsModule,
    NgxStripeModule.forRoot('pk_test_sKwY3Ldq0Nip6piTzEQt2glg'),
    BrowserAnimationsModule,
    ToastrModule.forRoot()
  ],  
  providers: [
   { provide: LocationStrategy, useClass: HashLocationStrategy}
  ],
  bootstrap: [AppComponent] 
})
export class AppModule { }
