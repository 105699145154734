import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PaymentFailureComponent } from './payment-failure/payment-failure.component';
import { PaymentSuccessComponent } from './payment-success/payment-success.component';
import { StripeComponent } from './stripe/stripe.component';
import { SubscriptionComponent } from './subscription/subscription.component';

const routes: Routes = [
  { path: "stripe/:session", component: StripeComponent },
  { path: "subscription/:session", component: SubscriptionComponent },
  { path: "payment-success", component: PaymentSuccessComponent },
  { path: "payment-failure", component: PaymentFailureComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
