import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {loadStripe} from '@stripe/stripe-js';
@Component({
  selector: 'app-subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.css']
})
export class SubscriptionComponent implements OnInit {
  url = "https://oonxvy0hcd.execute-api.eu-west-2.amazonaws.com/DEV"
  session;
  stripe;
  constructor(private route:ActivatedRoute,private http:HttpClient) {
    this.session = route.snapshot.paramMap.get("session");  
    this.getCheckoutPage();
   }
   async getCheckoutPage(){
    const sessionDetsils:any = await this.getTokenDetails(this.session);
    this.stripe = await loadStripe('pk_test_sKwY3Ldq0Nip6piTzEQt2glg',{
      stripeAccount:sessionDetsils.ConnectedAccountID
    });
    await this.stripe.redirectToCheckout({sessionId:this.session})
   }
   getTokenDetails(token){
    return new Promise((res,rej)=>{
      this.http.get(`${this.url}/stripeconnect/intentmetadata/${token}`).subscribe((details:any)=>{
        if(details.data){
          res(details.data)
        }else(
          rej(null)
        )
      },err=>{
        rej(null)
      })
    })
   }
  ngOnInit(): void {
  }

}
