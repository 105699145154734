
<div class="payment-container">
        <span class="material-icons success">
            check_circle_outline
        </span>
    <p class="fw-600">
        Payment Successful
    </p>
    <p>
        Please close your browser.
    </p>
</div>